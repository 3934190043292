/**
 * Created by sam on 18/03/2014.
 */
var jsValidate = {
  "checkedRadios": [],
  "dataTypes": ["text", "email", "phone", "numeric", "custom"],
  "errorTypes": {
    "ERROR_REQUIRED": "This field is required",
    "ERROR_TOOSHORT": "This field must be at least %s characters",
    "ERROR_TOOLONG": "This field must be less than %s characters",
    "ERROR_NOTEMAIL": "This field does not contain a valid e-mail address",
    "ERROR_NOTPHONE": "This field does not contain a valid phone number",
    "ERROR_NOTNUMERIC": "This field must be numeric",
    "ERROR_BELOWMIN": "This field must be at least %s",
    "ERROR_ABOVEMAX": "This field must be less than %s"
  },
  //REGEX PATTERNS
  "patterns": {
    /*
     One or more letters and numbers
     One or more letters, numbers, underscores, . and -
     Optional + followed by letters, numbers and hyphen
     @
     One or more groups of 2 - 63 letters, numbers and hyphens followed by a dot
     Two or three letters
     */
    "email": /^[A-z\d]+[\w\.-]+(\+[\w-]+)?@([A-z\d-]{2,63}\.)+[A-z]{2,3}$/,
    /*
     Zero or one -
     Optional digits
     Zero or one .
     One or more digits
     */
    "numeric": /^-?\d*\.?\d+$/,
    /*
     Zero or one +
     Optional ( followed by one or more digits and )
     One or more digits and -
     */
    "phone": /^\+?(\(\d+\))?[\d-]+$/
  },
  //ERROR FUNCTIONS
  "clearFieldError": function(field) {
    var $field = $(field);
    $field.removeClass('error');
    $("label[for='" + $field.attr("id") + "']").removeClass("error");
    $field.parent().find(".errorIcon").remove();
  },
  "setFieldError": function(field, type) {
    var $field = $(field);
    $field.addClass('error');
    $("label[for='" + $field.attr("id") + "']").addClass("error");
    var errorMessage = type;
    switch(type) {
      case jsValidate.errorTypes.ERROR_TOOSHORT:
        errorMessage = errorMessage.replace("%s", $field.attr("data-minlength"));
        break;
      case jsValidate.errorTypes.ERROR_TOOLONG:
        errorMessage = errorMessage.replace("%s", $field.attr("data-maxlength"));
        break;
      case jsValidate.errorTypes.ERROR_BELOWMIN:
        errorMessage = errorMessage.replace("%s", $field.attr("data-minvalue"));
        break;
      case jsValidate.errorTypes.ERROR_ABOVEMAX:
        errorMessage = errorMessage.replace("%s", $field.attr("data-maxvalue"));
        break;
    }
    var $errorIcon = $("<span></span>").addClass("errorIcon").poshytip({
      content: errorMessage,
      showOn: "hover",
      alignTo: "target",
      alignX: "right",
      alignY: "center",
      offsetX: 10
    });
    $field.parent().append($errorIcon);
  },
  "setFormError": function($field) {
    var $form = $field.closest("form.js-validate");
    $form.data("jsvalidate", true);
  },
  //VALIDATE FUNCTION
  "validateField": function(field) {
    var isValid = true;
    var $field = $(field);
    if($field.attr("data-required") == "true") {
      isValid = isValid && jsValidate.checkHasValue(field);
    }
    if(!isValid) {
      jsValidate.setFormError($field);
      return;
    }
    if(field.type == "radio") {
      return;
    }
    if($field.attr("data-type") == "" || jsValidate.dataTypes.indexOf($field.attr("data-type")) == -1) {
      //Invalid data type, default to field type
      var tagName = field.tagName.toLowerCase();
      if(tagName == "textarea" || tagName == "select" || typeof $field.attr("type") == "undefined") {
        $field.attr("data-type", "text");
        return;
      }
      var type = $field.attr("type").toLowerCase();
      var newType;
      switch(type) {
        case "email":
          newType = "email";
          break;
        case "tel":
          newType = "phone";
          break;
        default:
          newType = "text";
      }
      $field.attr("data-type", newType);
    }
    isValid = isValid && jsValidate.checkMinLength(field);
    if(!isValid) { jsValidate.setFormError($field); return; }
    isValid = isValid && jsValidate.checkMaxLength(field);
    if(!isValid) { jsValidate.setFormError($field); return; }
    switch($field.attr("data-type")) {
      case "text":
        break;
      case "email":
        isValid = isValid && jsValidate.checkEmail(field);
        break;
      case "phone":
        isValid = isValid && jsValidate.checkPhone(field);
        break;
      case "numeric":
        isValid = isValid && jsValidate.checkNumeric(field);
        if(!isValid) { jsValidate.setFormError($field); return; }
        isValid = isValid && jsValidate.checkMinValue(field);
        if(!isValid) { jsValidate.setFormError($field); return; }
        isValid = isValid && jsValidate.checkMaxValue(field);
        break;
      case "custom":
        break;
    }
    if(isValid) {
      jsValidate.clearFieldError(field);
    } else {
      jsValidate.setFormError($field);
    }
  },
  //BEGIN CHECK FUNCTIONS
  "checkEmail": function(field) {
    if(!jsValidate.patterns.email.test(field.value)) {
      jsValidate.setFieldError(field, jsValidate.errorTypes.ERROR_NOTEMAIL);
      return false;
    }
    return true;
  },
  "checkHasValue": function(field) {
    if(field.type == "radio") {
      if(jsValidate.checkedRadios[field.name] == true) {
        //Already checked this by checking another radio in this group
        return true;
      }
      jsValidate.checkedRadios[field.name] = true;
      if($("input[name='" + field.name + "']:checked").length == 0) {
        jsValidate.setFieldError(field, jsValidate.errorTypes.ERROR_REQUIRED);
        return false;
      }
    } else {
      if((field.type == "checkbox" && !$(field).prop("checked")) || field.value == "") {
        jsValidate.setFieldError(field, jsValidate.errorTypes.ERROR_REQUIRED);
        return false;
      }
    }
    return true;
  },
  "checkMaxLength": function(field) {
    var $field = $(field);
    var maxLen = $field.attr("data-maxlength");
    if(typeof maxLen == "undefined") {
      //Attribute not set
      return true;
    }
    if(!jsValidate.patterns.numeric.test(maxLen)) {
      //Not a valid number
      return true;
    }
    if($field.val().length > parseInt(maxLen)) {
      jsValidate.setFieldError(field, jsValidate.errorTypes.ERROR_TOOLONG);
      return false;
    }
    return true;
  },
  "checkMaxValue": function(field) {
    var $field = $(field);
    var maxVal = $field.attr("data-maxvalue");
    if(typeof maxVal == "undefined") {
      //Attribute not set
      return true;
    }
    if(!jsValidate.patterns.numeric.test(maxVal) || !jsValidate.patterns.numeric.test($field.val())) {
      //Not a valid number
      return true;
    }
    if(parseFloat($field.val()) > parseFloat(maxVal)) {
      jsValidate.setFieldError(field, jsValidate.errorTypes.ERROR_ABOVEMAX);
      return false;
    }
    return true;
  },
  "checkMinLength": function(field) {
    var $field = $(field);
    var minLen = $field.attr("data-minlength");
    if(typeof minLen == "undefined") {
      //Attribute not set
      return true;
    }
    if(!jsValidate.patterns.numeric.test(minLen)) {
      //Not a valid number
      return true;
    }
    if($field.val().length < parseInt(minLen)) {
      jsValidate.setFieldError(field, jsValidate.errorTypes.ERROR_TOOSHORT);
      return false;
    }
    return true;
  },
  "checkMinValue": function(field) {
    var $field = $(field);
    var minVal = $field.attr("data-minvalue");
    if(typeof minVal == "undefined") {
      //Attribute not set
      return true;
    }
    if(!jsValidate.patterns.numeric.test(minVal) || !jsValidate.patterns.numeric.test($field.val())) {
      //Not a valid number
      return true;
    }
    if(parseFloat($field.val()) < parseFloat(minVal)) {
      jsValidate.setFieldError(field, jsValidate.errorTypes.ERROR_BELOWMIN);
      return false;
    }
    return true;
  },
  "checkNumeric": function(field) {
    if(!jsValidate.patterns.numeric.test(field.value)) {
      jsValidate.setFieldError(field, jsValidate.errorTypes.ERROR_NOTNUMERIC);
      return false;
    }
    return true;
  },
  "checkPhone": function(field) {
    if(!jsValidate.patterns.phone.test(field.value)) {
      jsValidate.setFieldError(field, jsValidate.errorTypes.ERROR_NOTPHONE);
      return false;
    }
    return true;
  }
};
$(document).ready(function() {
  $("form.js-validate").on("submit", function(e) {
    var $this = $(this);
    $this.find("span.errorIcon").remove();
    $this.data("jsvalidate", false);
    $this.find(".js-validatefield").each(function() {
      jsValidate.validateField(this);
    });
    if($this.data("jsvalidate") == true) {
      e.preventDefault();
    }
  }).find(".js-validatefield").on("blur", function() {
    jsValidate.validateField(this);
  }).on("focus", function() {
    jsValidate.clearFieldError(this);
  });
});
